"use strict";
jQuery(document).ready(function ($) {
  $("#scroll-down").click(function () {
    $("html,body").animate(
      {
        scrollTop: $(".grid-container.home.section-two.fluid").offset().top,
      },
      "slow"
    );
  });

  $("#searchfield").on("input", function () {
    $("#input-wrapper").removeClass("close");
    $("#results").html("");
    var inputText = $(this).val();
    if (inputText.length > 2) {
      $("#input-wrapper").addClass("loading");
      $.ajax({
        url:
          "https://gommaire-b2c.yournewwebsite.be/wp-json/wp/v2/product?_embed=wp:term&search=" +
          inputText,
        type: "get",
        success: function (response) {
          $("#results").html("");
          if (response.length > 0) {
            response.map((result) => {
              var categoryLabel = "";
              if (result._embedded["wp:term"].length > 0) {
                result._embedded["wp:term"][0].map((category) => {
                  console.log(category);
                  if (
                    category.id == 11 ||
                    category.id == 13 ||
                    category.id == 88
                  ) {
                    categoryLabel = category.name;
                  }
                });
              }

              $("#results").append(`
                <div class="result">
                  <a class="link-wrapper" href="${result.link}">
                    <div class="image">
                    
                    <img src="${result.fimg_url}" />
                    
                    </div>
                    <div class="content">
                    <p class="top-title">${categoryLabel}</p>
                    <p class="title">${result.title.rendered}</p>
                      <button class="button1">Discover</button>
                    </div>
                  </a>
                </div>
                `);
            });
            $("#input-wrapper").removeClass("loading");
            $("#input-wrapper").addClass("close");
          } else {
            $("#results").html("");
            $("#results").html(`
				<h4>No results</h3>
			`);
            $("#input-wrapper").removeClass("loading");
            $("#input-wrapper").addClass("close");
          }
        },
        error: function (response) {
          console.log(response);
          $("#input-wrapper").removeClass("loading");
          $("#input-wrapper").addClass("close");
        },
      });
    }
  });
  $("#close").on("click", function () {
    $("#searchfield").val("");
    $("#input-wrapper").removeClass("close");
    $("#input-wrapper").removeClass("loading");
    $("#results").html("");
  });
});
